import React, { PropsWithChildren } from 'react';

import { AppEventTarget } from './globalEvents';

export const appEventDispatcher = new AppEventTarget();

export const AppEventDispatcherContext =
  React.createContext(appEventDispatcher);

const AppEventDispatcherProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  return (
    <AppEventDispatcherContext.Provider value={appEventDispatcher}>
      {children}
    </AppEventDispatcherContext.Provider>
  );
};

export default AppEventDispatcherProvider;
