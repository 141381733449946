import createClient from 'openapi-fetch';
import type { paths } from './';

export const makeOpenApiClient = (options?: RequestInit) =>
  createClient<paths>({
    baseUrl: process.env.GATSBY_OPENAPI_ENDPOINT,
    credentials: 'same-origin',
    ...options
  });

export default makeOpenApiClient();
