import React, { useContext } from 'react';
import type { AppSiteNavWithLocale } from '../../gatsby/types';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import { findRouteInTree } from 'lib/navigation/global';
import { makeDynamicPath } from 'lib/navigation/dynamic';

export const AppNavContext = React.createContext<
  AppSiteNavWithLocale | undefined
>(undefined);

export function useAppNavTree() {
  const appNav = useContext(AppNavContext);
  if (!appNav) {
    throw Error('AppNavContext not initiated');
  }
  return appNav;
}

export const useRoutePath = (key: MAIN_NAVIGATION_ROUTER_KEY) => {
  const appNav = useAppNavTree();
  return findRouteInTree(appNav, key);
};

/**
 * Product categories
 */

// export const useProductCategoriesNavTree = () =>
//   useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.ALL_PRODUCTS) as ProductCategoryRoute;

// export const useTopProductCats = (state: StoreState) =>
//   useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.ALL_PRODUCTS)(state)?.items as
//     | ProductCategoryRoute[];

// export const useProductCatNav = (name: string) => (state: StoreState) => {
//   const cats = useProductCategoriesNavTree(state);
//   return cats && findProductCatInTree(cats, name);
// };

/**
 * Dynamic path builders
 */

const useDynamicPath = (key: MAIN_NAVIGATION_ROUTER_KEY, slug: string) => {
  const appNav = useAppNavTree();

  if (!slug) {
    throw Error('Unable to build dynamic link with empty slug');
  }

  return makeDynamicPath(key)(appNav, slug);
};

export const useProductPath = (slug: string) =>
  useDynamicPath(MAIN_NAVIGATION_ROUTER_KEY.PRODUCT, slug);

export const useIngredientPath = (slug: string) =>
  useDynamicPath(MAIN_NAVIGATION_ROUTER_KEY.INGREDIENTS, slug);
