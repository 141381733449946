import React, { PropsWithChildren, useEffect } from 'react';

import { AppEvents } from 'lib/events/contracts';
import { loggers } from '.';
import useAppEventDispatcher from 'lib/events/hooks';

const log = loggers.event;

const LogProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const eventDispatcher = useAppEventDispatcher();
  useEffect(<Name extends keyof AppEvents>() => {
    const unsubscribeEvents = eventDispatcher.subscribeToAll(
      (
        name: Name,
        ...[payload]: AppEvents[Name] extends undefined ? [] : [AppEvents[Name]]
      ) => {
        if (payload) {
          log.info(name, { payload });
        } else {
          log.info(name);
        }
      }
    );
    const unsubscribeErrors = eventDispatcher.subscribeToAllErrors(
      (name, payload) => {
        log.error(new Error(name), payload);
      }
    );

    return () => {
      unsubscribeEvents();
      unsubscribeErrors();
    };
  });

  return <>{children}</>;
};

export default LogProvider;
