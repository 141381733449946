import React, { PropsWithChildren } from 'react';

import { loggers } from '../../lib/log';

const loadSentry = () =>
  import(/* webpackChunkName: "errors-init" */ '../../lib/sentry/init');

type BoundaryState = {
  hasError: boolean;
};

export default class GlobalErrorBoundary extends React.Component<
  PropsWithChildren,
  BoundaryState
> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    loadSentry().then(({ initSentry }) => initSentry());
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    loggers.ui.error(error, { errorInfo });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }
    // TODO: return error page
  }
}
