import 'styles/fonts/font-face.css';
import 'styles/tailwind/index.css';
import 'styles/animations.scss';

import {
  type NavigateOptions,
  useLocation,
  type WindowLocation
} from '@reach/router';
import {
  AnalyticsPipeline,
  AnalyticsPipelineBrowser
} from '@svelte/features/analytics/analyticsPipeline';
import MarketingNotifier from '@svelte/reactify/features/MarketingNotifier';

import ResponsiveComponent from 'components/atoms/layout/Responsive';
import { type GatsbyBrowser, navigate } from 'gatsby';
import DeviceStateProvider from 'lib/device/ReactProvider';
import { Breakpoint } from 'lib/device/state';
import { AppEventName } from 'lib/events/contracts';
import useAppEventDispatcher from 'lib/events/hooks';
import AppEventDispatcherProvider, {
  appEventDispatcher
} from 'lib/events/Provider';
import { setupGlobalLog } from 'lib/log';
import LogProvider from 'lib/log/Provider';
import MetaPixel from 'lib/meta-pixel/MetaPixel';
import MarketingAttribution from 'lib/navigation/attribution';
import { makeDynamicPath } from 'lib/navigation/dynamic';
import { useWritable } from 'lib/react-svelte/reactifyStores';
import GlobalErrorBoundary from 'lib/sentry/ErrorBoundary';
import React, { useEffect, useRef } from 'react';
import { Provider as BalancerProvider } from 'react-wrap-balancer';
import { AppStateSync } from 'state/AppStateSync';
import { GraphQLWorkerProvider } from 'state/context/GraphqlWorkerProvider';
import { AppNavContext } from 'state/context/nav';
import { currentRouteKeyStore } from 'state/stores/nav';

import type { AppPageContext, AppSiteNavWithLocale } from './types';

// import posthog from 'posthog-js';

const DesktopCommsMenu = React.lazy(
  () =>
    import(
      /* webpackChunkName: "desktop-comms" */ '@svelte/reactify/features/DesktopCommsMenu'
    )
);

const MobileCommsMenu = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mobile-comms" */ '@svelte/reactify/features/MobileCommsMenu'
    )
);

setupGlobalLog();

type ScrollLocation = globalThis.Location & NavigateOptions<unknown>;

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  // routerProps: { location },
  getSavedScrollPosition,
  prevRouterProps
}) => {
  const prevLocation = prevRouterProps?.location as ScrollLocation | undefined;
  // TODO: redo when new cat pages are in
  // const allCatsPath = useProductCategoriesNavTree(getState()).path;
  // const isCategoryPage = location.pathname.includes(allCatsPath);
  // const navigatedFromCategoryPage =
  //   prevRouterProps?.location.pathname.includes(allCatsPath);

  // const shouldRetainScroll = isCategoryPage && navigatedFromCategoryPage;
  const shouldRetainScroll = false;

  if (shouldRetainScroll) {
    const queriedPosition = getSavedScrollPosition(prevLocation);
    window.scrollTo(queriedPosition);
    return false;
  }

  return true;
};

// Wrapper are necessary in Browser because hooks are not allowed in exported functions
const RootWrapper: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  // /* TODO: re-enable when no library use UNSAFE_componentWillMount. Even reach-router does */
  // /* <React.StrictMode> */

  return (
    <GlobalErrorBoundary>
      <AppEventDispatcherProvider>
        <DeviceStateProvider>
          <LogProvider>
            <GraphQLWorkerProvider>
              <AppStateSync>
                <MarketingAttribution>
                  <BalancerProvider>{element}</BalancerProvider>
                </MarketingAttribution>
              </AppStateSync>
            </GraphQLWorkerProvider>
          </LogProvider>
        </DeviceStateProvider>
      </AppEventDispatcherProvider>
    </GlobalErrorBoundary>
  );
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = props => (
  <RootWrapper {...props} />
);

/**
 * wrapPageElement has a different API and can pass down PageProps
 */
export const WrapPageElementWrapper: GatsbyBrowser['wrapPageElement'] = ({
  props,
  element
}) => {
  // NOTE: pageContext won't exist on a non-existent page (i.e.: before gatsby redirects to 404 on some strange url)
  const context = props.pageContext as AppPageContext | undefined;
  const { currentRouteKey } = context || {};
  const [, setCurrentRouteKey] = useWritable(currentRouteKeyStore);
  const location = useLocation();
  const previousLocation = useRef<WindowLocation | undefined>();
  const lastNavigationTime = useRef(new Date());
  const appDispatcher = useAppEventDispatcher();
  const lastPageLeft = useRef<string | null>(null);

  // NOTE: uncomment when developing posthog events
  // useEffect(() => {
  //   posthog.init('phc_FHOE7LYRJ6RXs06oFSQSzDkCXV2GLru9h3pvbyogzqG', {
  //     api_host: 'https://eu.i.posthog.com',
  //     person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
  //     autocapture: true
  //   });
  // }, []);

  useEffect(() => {
    if (context?.appNav && process.env.GATSBY_POSTHOG_DISABLED !== 'true') {
      const pipeline = new AnalyticsPipeline(
        context.appNav,
        new AnalyticsPipelineBrowser()
      );
      pipeline.init(appDispatcher);

      return () => pipeline.stop();
    }
  }, [context, appDispatcher]);

  useEffect(() => {
    if (context?.appNav) {
      appEventDispatcher.subscribeMemo(
        AppEventName.SvelteNavigation,
        ({ route, url }) => {
          const target = makeDynamicPath(route)(context.appNav, url);
          navigate(target);
        }
      );
    }
  }, [context]);

  useEffect(() => {
    if (process.env.GATSBY_GOOD_ON_YOU_AFFILIATE_ENABLED === 'true') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://www.km0trk.com/scripts/sdk/everflow.js';
      document.body.appendChild(script);

      const intervalId = setInterval(() => {
        // biome-ignore lint/suspicious/noExplicitAny: EF is a third part script
        const EF = (window as any).EF;
        if (typeof EF !== 'undefined') {
          clearInterval(intervalId);
          EF.click({
            offer_id: EF.urlParameter('oid'),
            affiliate_id: EF.urlParameter('affid'),
            sub1: EF.urlParameter('sub1'),
            sub2: EF.urlParameter('sub2'),
            sub3: EF.urlParameter('sub3'),
            sub4: EF.urlParameter('sub4'),
            sub5: EF.urlParameter('sub5'),
            uid: EF.urlParameter('uid'),
            source_id: EF.urlParameter('source_id'),
            transaction_id: EF.urlParameter('_ef_transaction_id')
          });
        }
      }, 1000);

      return () => {
        document.body.removeChild(script);
      };
    }

    if (process.env.GATSBY_META_PIXEL_ENABLED === 'true') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://www.km0trk.com/scripts/sdk/everflow.js';
      document.body.appendChild(script);

      const intervalId = setInterval(() => {
        // biome-ignore lint/suspicious/noExplicitAny: EF is a third part script
        const EF = (window as any).EF;
        if (typeof EF !== 'undefined') {
          clearInterval(intervalId);
          EF.click({
            offer_id: EF.urlParameter('oid'),
            affiliate_id: EF.urlParameter('affid'),
            sub1: EF.urlParameter('sub1'),
            sub2: EF.urlParameter('sub2'),
            sub3: EF.urlParameter('sub3'),
            sub4: EF.urlParameter('sub4'),
            sub5: EF.urlParameter('sub5'),
            uid: EF.urlParameter('uid'),
            source_id: EF.urlParameter('source_id'),
            transaction_id: EF.urlParameter('_ef_transaction_id')
          });
        }
      }, 1000);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const now = new Date();

      if (document.visibilityState === 'hidden') {
        const durationInMilliseconds = Math.abs(
          now.getTime() - lastNavigationTime.current.getTime()
        );
        const duration = Math.floor(durationInMilliseconds / 1000);
        appDispatcher.dispatch(AppEventName.PageLeft, { location, duration });
        lastPageLeft.current = location.href;
      } else if (lastPageLeft.current) {
        appDispatcher.dispatch(AppEventName.PageViewed, { location });
        lastPageLeft.current = null;
      }
    };

    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      true // make sure that this is called before listener in Analytics pipeline
    );

    let locationHasChanged = !previousLocation.current;
    if (previousLocation.current) {
      const { pathname, hash, search } = previousLocation.current;
      if (
        pathname !== location.pathname ||
        hash !== location.hash ||
        search !== location.search
      ) {
        locationHasChanged = true;
      }
    }

    if (locationHasChanged) {
      const pageLeft = previousLocation.current;
      const now = new Date();

      if (pageLeft) {
        const durationInMilliseconds = Math.abs(
          now.getTime() - lastNavigationTime.current.getTime()
        );
        const duration = Math.floor(durationInMilliseconds / 1000);
        appDispatcher.dispatch(AppEventName.PageLeft, {
          location: pageLeft,
          duration
        });
        appDispatcher.dispatch(AppEventName.PageViewed, {
          location,
          prev_duration: duration
        });
      } else {
        appDispatcher.dispatch(AppEventName.PageViewed, { location });
      }

      previousLocation.current = location;
      lastNavigationTime.current = now;
    }

    return () => {
      document.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        true
      );
    };
  }, [location, appDispatcher]);

  useEffect(() => {
    setCurrentRouteKey(currentRouteKey);
  }, [setCurrentRouteKey, currentRouteKey]);

  return (
    <AppNavContext.Provider value={context?.appNav}>
      {element}
      <MarketingNotifier appNavTree={context?.appNav as AppSiteNavWithLocale} />
      <ResponsiveComponent
        appNavTree={context?.appNav as AppSiteNavWithLocale}
        predicate={b => !!currentRouteKey && b < Breakpoint.LG}
        guidedCartContent={context?.guidedCartContent}
        LazyComponent={MobileCommsMenu}
      />
      <ResponsiveComponent
        appNavTree={context?.appNav as AppSiteNavWithLocale}
        predicate={b => !!currentRouteKey && b >= Breakpoint.LG}
        guidedCartContent={context?.guidedCartContent}
        LazyComponent={DesktopCommsMenu}
      />
      <MetaPixel enabled={process.env.GATSBY_META_PIXEL_ENABLED === 'true'} />
    </AppNavContext.Provider>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = props => (
  <WrapPageElementWrapper {...props} />
);

// export const onClientEntry: GatsbyBrowser['onClientEntry'] = async () => {
//   // As per https://caniuse.com/mdn-javascript_builtins_array_at
//   // only Safari 15.4+ (March 2022) supports it
//   if (typeof Array.prototype.at === 'undefined') {
//     // TODO: analytics event
//     const polyfill = await import(
//       /* webpackChunkName: "array-at" */ 'array.prototype.at'
//     );
//     polyfill.shim();
//   }
// };
