<!-- NOTE: Webpack is not clever enough, and two bundles are created when using above -->

<!-- <script lang="ts">
  import { disruptorServiceWorkerRemote } from '@svelte/service/worker/loader';
  import { setContextGraphqlWorker } from './context';
  import { DisruptorServiceWorkerAsync } from '../types';
  import { graphqlAsyncInterface } from '@svelte/service/worker/browser/worker';

  const asyncInterface: DisruptorServiceWorkerAsync =
    process.env.GATSBY_URQL_DEBUG === 'true'
      ? graphqlAsyncInterface
      : (disruptorServiceWorkerRemote as DisruptorServiceWorkerAsync);

  setContextGraphqlWorker(asyncInterface);

  // TODO: move here the subscription to AppEventDispatcher from React disruptorServiceWorker provider
</script> -->

<script lang="ts">
  import { disruptorServiceWorkerRemote } from '@svelte/service/worker/loader';
  import { setContextGraphqlWorker } from './context';
  import { DisruptorServiceWorkerAsync } from '../types';

  setContextGraphqlWorker(
    disruptorServiceWorkerRemote as DisruptorServiceWorkerAsync
  );

  // TODO: move here the subscription to AppEventDispatcher from React disruptorServiceWorker provider
</script>

<slot />
