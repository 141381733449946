import { Readable, writable } from 'svelte/store';

export type DeliveryRules = Readonly<{
  minSpend: number;
  deliveryFee: number;
}>;

export const __deliveryRulesStore = writable<DeliveryRules | null>(null);
export const deliveryRulesStore =
  __deliveryRulesStore as Readable<DeliveryRules | null>;

export const updateDeliveryRules = __deliveryRulesStore.set;
