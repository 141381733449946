import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';

import { DeliveryRules, deliveryRulesStore } from '@svelte/state/deliveryRules';
import { derived, get, writable } from 'svelte/store';

import { AppEventName } from '../../lib/events/contracts';
import { AppEventTarget } from '../../lib/events/globalEvents';
import { Exact } from 'gatsby/graphqlTypes';
import React from 'react';
import { deductAmount } from '../../lib/l10n';
import { loggers } from '../../lib/log';
import { omit } from '../../lib/util';
import useAppEventDispatcher from '../../lib/events/hooks';
import { DisruptorOperationResultState } from '@svelte/service/backend/queryStore';

const log = loggers.state;

export type CartProductRef = {
  productId: number;
  qty: number;
  skuId: number;
};

export type CartState = Exact<GqlSchemaWorker.CartRemoteFragment>;

export type CheckoutStateStore = {
  acceptedPoliciesCheckboxValue: boolean;
  checkoutInProgress: boolean;
  uid: string | null;
  cart: CartState | null;
  subscribedToNewsletterCheckboxValue: boolean;
};

export type CheckoutState = Omit<CheckoutStateStore, 'productCatalogue'> & {
  amounts: {
    discount: number;
    total: number;
    delivery: number;
    subtotal: number;
  };
  hydrated: boolean;
};

// NOTE: only use within state module
export const __checkoutStoreInternal = writable<CheckoutStateStore>({
  acceptedPoliciesCheckboxValue: false,
  checkoutInProgress: false,
  uid: null,
  cart: null,
  subscribedToNewsletterCheckboxValue: false
});

export function calculateCartAmounts(
  products: GqlSchemaWorker.ProductPriceFragment[],
  discount: CartState['discount'],
  deliveryRules: DeliveryRules
) {
  let subtotal = 0;

  for (const product of products) {
    for (const sku of product.skus) {
      subtotal += sku.data.price * sku.qty;
    }
  }

  const subtotalAfterDiscount = deductAmount(subtotal, discount?.amount);
  const delivery =
    subtotalAfterDiscount > deliveryRules.minSpend
      ? 0
      : deliveryRules.deliveryFee;

  return {
    discount: subtotal - subtotalAfterDiscount,
    total: subtotalAfterDiscount + delivery,
    delivery,
    subtotal: subtotalAfterDiscount
  };
}

function calculateCheckoutAmounts(
  cart: CheckoutStateStore['cart'],
  deliveryRules: DeliveryRules | null
): CheckoutState['amounts'] {
  if (cart && deliveryRules) {
    return calculateCartAmounts(cart.products, cart.discount, deliveryRules);
  }

  return {
    discount: 0,
    total: 0,
    delivery: 0,
    subtotal: 0
  };
}

export function updateCheckoutInProgress(value: boolean) {
  if (value !== get(__checkoutStoreInternal).checkoutInProgress) {
    __checkoutStoreInternal.update(
      (state): Exact<CheckoutStateStore> => ({
        ...state,
        checkoutInProgress: value
      })
    );
  }
}

export function resetCart() {
  __checkoutStoreInternal.update((state): Exact<CheckoutStateStore> => {
    return {
      ...state,
      cart: null
    };
  });
}

export const checkoutStore = derived<
  [typeof __checkoutStoreInternal, typeof deliveryRulesStore],
  CheckoutState
>([__checkoutStoreInternal, deliveryRulesStore], ([state, deliveryRules]) => {
  return {
    ...state,
    amounts: calculateCheckoutAmounts(state.cart, deliveryRules),
    hydrated: !!state.uid
  };
});
// NOTE: this is only invoiced discount in cart
// session also has a value for claimed discount.
// They should match, except when newsletter actions template handles conflict
export const invoicedDiscountStore = derived<
  typeof __checkoutStoreInternal,
  GqlSchemaWorker.DiscountFragment | undefined | null
>(__checkoutStoreInternal, state => {
  return state.cart?.discount;
});

export default checkoutStore;

/**
 * Batch updates to state
 * whenever user/session changes
 */
export function hydrateLocalCart(
  eventDispatcher: AppEventTarget,
  uid: string,
  remoteCart?: GqlSchemaWorker.CartRemoteFragment | null
) {
  __checkoutStoreInternal.update((state): Exact<CheckoutStateStore> => {
    if (uid === state.uid) {
      log.warn('Cart is already hydrated');
      return state;
    }

    const output = {
      ...state,
      uid,
      cart: remoteCart || null
    };

    eventDispatcher.dispatch(AppEventName.CartHydrated);

    return output;
  });
}

export function updateCart(
  eventDispatcher: AppEventTarget,
  next: GqlSchemaWorker.CartRemoteFragment
) {
  __checkoutStoreInternal.update((state): Exact<CheckoutStateStore> => {
    eventDispatcher.dispatch(AppEventName.CartUpdated);

    return {
      ...state,
      cart: next
    };
  });
}

const addToLocalCart =
  (eventDispatcher: AppEventTarget) =>
    (addedProduct: Exact<GqlSchemaWorker.CartProductFragment>, shouldNotify = true) => {
      __checkoutStoreInternal.update((state): Exact<CheckoutStateStore> => {
        const { cart } = state;

        const dispatchAddToCart = () => {
          for (const productSku of addedProduct.skus) {
            eventDispatcher.dispatch(AppEventName.AddedToCart, {
              ...omit(['skus'], addedProduct),
              sku: productSku,
              shouldNotify
            });
          }
        };

        if (!cart) {
          const nextState: CartState = {
            __typename: 'Cart',
            id: '',
            currency: 'GBP',
            products: [addedProduct]
          };
          eventDispatcher.dispatch(AppEventName.CartInitiated);
          dispatchAddToCart();

          return {
            ...state,
            cart: nextState
          };
        } else {
          const nextItems = [...cart.products];

          const productInCartIndex = cart.products.findIndex(
            p => p.id === addedProduct.id
          );
          const productInCart = cart.products[productInCartIndex];

          if (productInCart) {
            const nextSkus = [...productInCart.skus];

            for (const sku of addedProduct.skus) {
              const skuInCartIndex = nextSkus.findIndex(
                ({ data }) => data.id === sku.data.id
              );
              const skuInCart = nextSkus[skuInCartIndex];

              if (skuInCart) {
                nextSkus[skuInCartIndex] = {
                  ...skuInCart,
                  qty: skuInCart.qty + sku.qty
                };
              } else {
                nextSkus.push(sku);
              }
            }

            nextItems[productInCartIndex] = {
              ...productInCart,
              skus: nextSkus
            };
          } else {
            nextItems.push(addedProduct);
          }

          dispatchAddToCart();

          return {
            ...state,
            cart: {
              ...cart,
              products: nextItems
            }
          };
        }
      });
    };

export function useAddToCart() {
  const eventDispatcher = useAppEventDispatcher();
  return React.useCallback(addToLocalCart(eventDispatcher), [eventDispatcher]);
}

export type SvelteAddToCartData = {
  products: DisruptorOperationResultState<'allProducts'>,
  productId: number | undefined,
  skuId: number,
  appEventTarget: AppEventTarget,
  shouldNotify: boolean
}

export function svelteAddToLocalCart(
  { products, productId, skuId, appEventTarget, shouldNotify }: SvelteAddToCartData) {
  const fragment = products.data?.allProducts.find(
    item => item.id === productId
  );
  const skuData = fragment?.skus.find(s => s.id === skuId);

  fragment &&
    skuData &&
    addToLocalCart(appEventTarget)({
      ...fragment,
      __typename: 'CartProduct',
      skus: [
        {
          __typename: 'CartSku',
          qty: 1,
          data: skuData
        }
      ]
    }, shouldNotify);
}

export const removeFromCart =
  (eventDispatcher: AppEventTarget) => (skuId: number) => {
    __checkoutStoreInternal.update((state): Exact<CheckoutStateStore> => {
      const { cart } = state;
      if (!cart) {
        log.error(new Error('Cart not initialized'), { state });
        return state;
      }

      const productIndex = cart.products.findIndex(
        p => !!p.skus.find(s => s.data.id === skuId)
      );
      const productInCart = cart.products[productIndex];

      if (productIndex < 0) {
        // TODO: why this?
        return state;
      }

      if (productInCart) {
        const nextItems = [...cart.products];

        if (
          productInCart.skus.length === 1 &&
          productInCart.skus[0].data.id === skuId
        ) {
          /**
           * Remove product as no more skus in cart
           */
          nextItems.splice(productIndex, 1);
        } else {
          /**
           * Remove only one sku
           */
          const nextSkus = [...productInCart.skus];
          const skuIndex = nextSkus.findIndex(({ data }) => data.id === skuId);
          if (skuIndex > -1) {
            nextSkus.splice(skuIndex, 1);
            nextItems[productIndex] = {
              ...productInCart,
              skus: nextSkus
            };
          }
        }

        eventDispatcher.dispatch(AppEventName.RemovedFromCart, {
          productId: productInCart.id,
          skuId
        });

        return {
          ...state,
          cart: {
            ...cart,
            products: nextItems
          }
        };
      }

      log.error(new Error('No product to remove'), { state });

      return state;
    });
  };

export function useRemoveFromCart() {
  const eventDispatcher = useAppEventDispatcher();
  return React.useCallback(removeFromCart(eventDispatcher), [eventDispatcher]);
}

export const editSkuInCart =
  (eventDispatcher: AppEventTarget) => (itemRef: Exact<CartProductRef>) => {
    __checkoutStoreInternal.update((state): Exact<CheckoutStateStore> => {
      const { cart } = state;
      if (!cart) {
        log.error(new Error('Cart not initialized'), { state });
        return state;
      }

      const productIndex = cart.products.findIndex(
        p => !!p.skus.find(s => s.data.id === itemRef.skuId)
      );
      const productInCart = cart.products[productIndex];

      if (productIndex < 0) {
        // TODO: why this?
        return state;
      }

      if (productInCart) {
        const skuInCartIndex = productInCart.skus.findIndex(
          ({ data }) => data.id === itemRef.skuId
        );
        const skuInCart = productInCart.skus[skuInCartIndex];
        if (skuInCart) {
          const nextSkus = [...productInCart.skus];
          nextSkus[skuInCartIndex] = {
            ...skuInCart,
            qty: itemRef.qty
          };
          const cartNextItems = [...cart.products];
          cartNextItems[productIndex] = {
            ...productInCart,
            skus: nextSkus
          };

          eventDispatcher.dispatch(AppEventName.EditedCartItem, itemRef);

          return {
            ...state,
            cart: {
              ...cart,
              products: cartNextItems
            }
          };
        }
      }

      log.error(new Error('Unable to find product to edit'));

      return state;
    });
  };

export function useEditCartItem() {
  const eventDispatcher = useAppEventDispatcher();
  return React.useCallback(editSkuInCart(eventDispatcher), [eventDispatcher]);
}

export function setCheckoutCustomerDetails(
  details: GqlSchemaWorker.CartCustomerDetails
) {
  __checkoutStoreInternal.update((state): Exact<CheckoutStateStore> => {
    const { cart } = state;
    if (!cart) {
      log.error(new Error('Cart not initialized'), { state });
      return state;
    }

    const nextState: CartState = {
      ...cart,
      billingAddress: details.billingAddress
        ? {
          ...details.billingAddress
        }
        : undefined,
      deliveryAddress: details.shippingAddress
        ? {
          ...details.shippingAddress
        }
        : undefined,
      email: details.email
    };

    return {
      ...state,
      acceptedPoliciesCheckboxValue:
        details.acceptedPrivacyPolicy && details.acceptedTermsAndConditions,
      cart: nextState,
      subscribedToNewsletterCheckboxValue: details.subscribedToNewsletter
    };
  });
}
