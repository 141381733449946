import { Readable, derived, get, readable, writable } from 'svelte/store';
import {
  DisruptorServiceWorker,
  Variables,
  DisruptorServiceWorkerAsync,
  GraphQLOutput
} from '../worker/types';
import { canUseDom } from 'lib/util';
import type {
  CombinedError,
  Operation,
  OperationContext,
  OperationResult
} from '@urql/core';
import { DisruptorExtensions } from './graphql/extensions';
import * as Comlink from 'comlink';

export type QueryArgsLazy<T extends keyof DisruptorServiceWorker> = Readonly<{
  key: T;
  worker: DisruptorServiceWorkerAsync;
  context?: Partial<OperationContext>;
}>;

export type QueryArgs<T extends keyof DisruptorServiceWorker> =
  QueryArgsLazy<T> &
    Readonly<{
      variables: Variables<T>;
    }>;

export type DisruptorCombinedError = CombinedError & {
  disruptorExtensions: DisruptorExtensions;
};

export type DisruptorOperationResultState<
  T extends keyof DisruptorServiceWorker
> = Omit<
  OperationResult<GraphQLOutput<T>, Variables<T>>,
  'error' | 'operation'
> & {
  operation?: Operation<GraphQLOutput<T>, Variables<T>>;
  error?: DisruptorCombinedError;
  fetching: boolean;
};

export type DisruptorOperationResultLazyState<
  T extends keyof DisruptorServiceWorker
> = DisruptorOperationResultState<T> & {
  paused: boolean;
  start: (variables: Variables<T>) => void;
};

export type QueryStoreLazy<T extends keyof DisruptorServiceWorker> = Readable<
  DisruptorOperationResultLazyState<T>
>;

export type MutationArgs<T extends keyof DisruptorServiceWorker> = Readonly<{
  key: T;
  variables: Variables<T>;
  worker: Worker;
}>;

export function parseOpResult<T extends keyof DisruptorServiceWorker>(
  result: OperationResult<GraphQLOutput<T>, Variables<T>>
): DisruptorOperationResultState<T> {
  return {
    ...result,
    fetching: !(result.data || result.error),
    error: result.error
      ? {
          ...result.error,
          disruptorExtensions: new DisruptorExtensions(result.error)
        }
      : undefined
  };
}

export function makeInitialFetchingResult<
  T extends keyof DisruptorServiceWorker
>(): DisruptorOperationResultState<T> {
  return {
    operation: undefined,
    data: undefined,
    error: undefined,
    extensions: undefined,
    fetching: true,
    hasNext: false,
    stale: false
  };
}

export function makeInitialNonFetchingResult<
  T extends keyof DisruptorServiceWorker
>(): DisruptorOperationResultState<T> {
  return {
    ...makeInitialFetchingResult(),
    fetching: false
  };
}

export function queryStore<T extends keyof DisruptorServiceWorker>({
  context,
  key,
  variables,
  worker
}: QueryArgs<T>): Readable<DisruptorOperationResultState<T>> {
  if (!canUseDom()) {
    return writable(makeInitialFetchingResult<T>());
  }

  const store = writable(makeInitialFetchingResult<T>(), set => {
    let subId: string | undefined;
    worker
      .query(
        {
          key,
          variables
        },
        Comlink.proxy(result => {
          set(
            parseOpResult(
              result as OperationResult<GraphQLOutput<T>, Variables<T>>
            )
          );
        }),
        context
      )
      .then(id => {
        subId = id;
      });

    return () => {
      if (subId) {
        worker.unsubscribe(subId);
      }
    };
  });

  return store;
}

export function queryStoreLazy<T extends keyof DisruptorServiceWorker>({
  context,
  key,
  worker
}: QueryArgsLazy<T>): QueryStoreLazy<T> {
  const requestStateStore = writable<{
    paused: boolean;
    variables: null | Variables<T>;
  }>({
    paused: true,
    variables: null
  });

  const stateStore = writable(makeInitialNonFetchingResult<T>(), set => {
    let subId: string | undefined;
    let requestFired = false;

    const unsubscribeStartedStore = requestStateStore.subscribe(
      requestState => {
        if (!requestFired && !requestState.paused) {
          requestFired = true;

          worker
            .query(
              {
                key,
                variables: requestState.variables as Variables<T>
              },
              Comlink.proxy(result => {
                set(
                  parseOpResult(
                    result as OperationResult<GraphQLOutput<T>, Variables<T>>
                  )
                );
              }),
              context
            )
            .then(id => {
              subId = id;
            });

          return () => {
            if (subId) {
              worker.unsubscribe(subId);
            }
          };
        }
      }
    );

    return () => {
      unsubscribeStartedStore();
      if (subId) {
        worker.unsubscribe(subId);
      }
    };
  });

  return derived([requestStateStore, stateStore], ([requestState, state]) => {
    return {
      ...state,
      fetching: !requestState.paused && !state.error && !state.data,
      paused: requestState.paused,
      start: (variables: Variables<T>) => {
        if (get(requestStateStore).paused) {
          requestStateStore.set({
            paused: false,
            variables
          });
        }
      }
    };
  });
}

export function makeQueryPromise<T extends keyof DisruptorServiceWorker>({
  context,
  key,
  variables,
  worker
}: QueryArgs<T>): Promise<DisruptorOperationResultState<T>> {
  if (!canUseDom()) {
    return Promise.resolve(makeInitialFetchingResult<T>());
  }

  return worker.queryPromise({ key, variables }, context).then(parseOpResult);
}

export function mutationStore<T extends keyof DisruptorServiceWorker>({
  context,
  key,
  worker
}: Omit<QueryArgs<T>, 'variables'>): Readable<
  DisruptorOperationResultState<T> & {
    mutate: (variables: Variables<T>) => void;
  }
> {
  if (!canUseDom()) {
    return readable({
      ...makeInitialNonFetchingResult<T>(),
      mutate: () => {}
    });
  }

  const variablesStore = writable<null | Variables<T>>(null);

  const stateStore = writable(makeInitialNonFetchingResult<T>(), set => {
    let subId: string | undefined;
    let requestFired = false;

    const unsubscribeStartedStore = variablesStore.subscribe(variables => {
      if (!requestFired && variables) {
        requestFired = true;

        worker
          .mutation(
            {
              key,
              variables
            },
            Comlink.proxy(result => {
              set(parseOpResult(result));
            }),
            context
          )
          .then(id => {
            subId = id;
          });

        return () => {
          if (subId) {
            worker.unsubscribe(subId);
          }
        };
      }
    });

    return () => {
      unsubscribeStartedStore();
      if (subId) {
        worker.unsubscribe(subId);
      }
    };
  });

  return derived([variablesStore, stateStore], ([variables, state]) => {
    return {
      ...state,
      fetching: Boolean(variables && !state.error && !state.data),
      mutate: (variables: Variables<T>) => {
        if (!get(variablesStore)) {
          variablesStore.set(variables);
        }
      }
    };
  });
}

export function makeMutationPromise<T extends keyof DisruptorServiceWorker>({
  context,
  key,
  variables,
  worker
}: QueryArgs<T>): Promise<DisruptorOperationResultState<T>> {
  if (!canUseDom()) {
    return Promise.resolve(makeInitialFetchingResult<T>());
  }

  return worker
    .mutationPromise({ key, variables }, context)
    .then(parseOpResult);
}
