import { graphql, useStaticQuery } from 'gatsby';

import { DeliveryRules } from '@svelte/state/deliveryRules';

const DELIVERY_RULES_QUERY = graphql`
  query DeliveryRules {
    c: strapiSiteConfig {
      m: minSpendForFreeDelivery
      f: deliveryFee
    }
  }
`;

export default function useDeliveryRules(): DeliveryRules {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = useStaticQuery(DELIVERY_RULES_QUERY);

  return { minSpend: data.c.m, deliveryFee: data.c.f };
}
