<script lang="ts">
  import MarketingNotifier from '@svelte/features/comms-menu/marketing-notifier/MarketingNotifier.svelte';
  import { appEventDispatcher } from 'lib/events/Provider';
  import { disruptorServiceWorkerRemote } from '@svelte/service/worker/loader';
  import { DisruptorServiceWorkerAsync } from '@svelte/service/worker/types';
  import WithContexts from '../WithContexts.svelte';
  import type { AppSiteNavWithLocale } from 'gatsby/types';

  export let appNavTree: AppSiteNavWithLocale;

  const queryWorker =
    disruptorServiceWorkerRemote as DisruptorServiceWorkerAsync;
</script>

<WithContexts {appNavTree}>
  <MarketingNotifier eventHandler={appEventDispatcher} {queryWorker} />
</WithContexts>
