import { useEffect, useState } from 'react';
import { Readable, Updater, Writable, get } from 'svelte/store';

export function useReadable<T>(store: Readable<T>): Readonly<T> {
  const [value, set] = useState<T>(get(store));

  useEffect(() => {
    const unsubscribe = store.subscribe(set);
    return unsubscribe;
  }, [store]);

  return value;
}

export function useWritable<T>(
  store: Writable<T>
): [Readonly<T>, (v: T) => void, (v: Updater<T>) => void] {
  const [value, set] = useState<T>(get(store));

  useEffect(() => {
    const unsubscribe = store.subscribe(set);
    return unsubscribe;
  }, [store]);

  return [value, store.set, store.update];
}
