import React, { useEffect, useRef } from 'react';
import { DeviceStateManager } from './deviceState';
import { connectionStore, lowBatteryStore, windowDimensions } from './state';

const DeviceStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const manager = useRef(
    new DeviceStateManager(connectionStore, lowBatteryStore, windowDimensions)
  );

  useEffect(() => {
    manager.current.init();

    return () => manager.current.stop();
  }, []);

  return <>{children}</>;
};

export default DeviceStateProvider;
