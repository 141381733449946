import { Writable, derived, writable } from 'svelte/store';
import { getContext, setContext } from 'svelte';

import { AppSiteNavWithLocale } from 'gatsby/types';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import { findRouteInTree } from 'lib/navigation/global';

type RoutesStore = Writable<AppSiteNavWithLocale>;

export const ROUTES_KEY = Symbol('routes');

export function setRoutesContext(appNavTree: AppSiteNavWithLocale) {
  setContext(ROUTES_KEY, writable(appNavTree));
}

export function useAppNavTree() {
  const appNav = getContext<RoutesStore>(ROUTES_KEY);
  if (!appNav) {
    throw Error('AppNavContext not initiated');
  }
  return appNav;
}

export const useRoutePath = (key: MAIN_NAVIGATION_ROUTER_KEY) => {
  const appNav = useAppNavTree();
  return derived(appNav, value => value && findRouteInTree(value, key));
};
