export const enum NotificationErrorLevel {
  Success,
  Info,
  Warn,
  Error
}

export const enum NotificationKind {
  AddedToCart,
  NewsletterPrompt,
  Error
}

export type NotificationData = {
  id: string;
};

export type ErrorNotification = NotificationData & {
  kind: NotificationKind.Error;
  level: NotificationErrorLevel;
  content: string;
};

export type AddedToCartNotification = NotificationData & {
  kind: NotificationKind.AddedToCart;
  name: string;
  price: number;
  productId: number;
  skuId: number;
  skuName: string;
  info?: string;
};

export type NewsletterPromptNotification = NotificationData & {
  kind: NotificationKind.NewsletterPrompt;
  discount: number;
};

export type ErrorNotificationPayload = Omit<ErrorNotification, 'id'>;

export type Notification =
  | AddedToCartNotification
  | NewsletterPromptNotification
  | ErrorNotification;
