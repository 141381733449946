import type Backoff from 'backo2';

export const runOrRetryAsync =
  (retryBackoff: Backoff) => async (op: () => unknown) => {
    try {
      await op();
    } catch (error) {
      const nextDuration = retryBackoff.duration();
      const giveUp = nextDuration === retryBackoff.max;

      if (giveUp) {
        throw error;
      } else {
        setTimeout(() => {
          op();
        }, nextDuration);
      }
    }
  };
