import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';

import { assertUnreachable } from '../util';
// import { loggers } from 'lib/log';
//
// const log = loggers.ui;

// function validateInt(value: number) {
//   if (!Number.isSafeInteger(value) || (value !== 0 && value < 100)) {
//     const error = new Error('Invalid price');
//     log.error(error, { value });
//     throw error;
//   }
// }

export function priceToString(priceIntegerWithDecimals: number): string {
  // validateInt(priceIntegerWithDecimals);

  const priceFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'gbp'

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return priceFormatter.format(priceIntegerWithDecimals / 100);
}

function deductPercentage(
  priceIntegerWithDecimals: number,
  percentage: number
): number {
  if (percentage === 0) {
    return priceIntegerWithDecimals;
  } else {
    const rounder = (priceIntegerWithDecimals * percentage) % 100 >= 50 ? 1 : 0;
    const discounted = (priceIntegerWithDecimals * percentage) / 100 + rounder;
    return priceIntegerWithDecimals - discounted;
  }
}

export function deductAmount(
  priceIntegerWithDecimals: number,
  amount?: GqlSchemaWorker.DiscountFragment['amount']
) {
  // validateInt(priceIntegerWithDecimals);

  if (priceIntegerWithDecimals === 0 || !amount) {
    return priceIntegerWithDecimals;
  }

  const { __typename } = amount;
  switch (__typename) {
    case 'DiscountPercentage':
      return deductPercentage(priceIntegerWithDecimals, amount.value);
    case 'DiscountCurrency':
      return priceIntegerWithDecimals - amount.amount;
    case 'DiscountFreeDelivery':
      return priceIntegerWithDecimals;
    default:
      assertUnreachable(__typename);
  }
}
