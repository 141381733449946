import { useEffect, useState } from 'react';

import { canUseDom } from 'lib/util';
import { getWindowDimensions } from 'lib/device/deviceState';
import { APP_BREAKPOINTS, Breakpoint } from 'lib/device/state';

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useCurrentBreakpoint(): Breakpoint | null {
  if (!canUseDom()) {
    return null;
  }

  const { width } = useWindowDimensions();

  let bkp = Breakpoint.SM;
  for (const b of APP_BREAKPOINTS) {
    if (width > b) {
      bkp = b;
    }
  }

  return bkp;
}
