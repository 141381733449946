import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { disruptorServiceWorkerRemote } from '@svelte/service/worker/loader';
import { canUseDom } from 'lib/util';
import useAppEventDispatcher from 'lib/events/hooks';
import { DisruptorServiceWorkerAsync } from '@svelte/service/worker/types';
import { AppEvents } from 'lib/events/contracts';
import { RemoteCartStateMachine } from '@svelte/service/cartSync';
import { appEventDispatcher } from 'lib/events/Provider';
import checkoutState, { __checkoutStoreInternal } from 'state/stores/checkout';
import { proxy } from 'comlink';
// import { graphqlAsyncInterface } from '@svelte/service/worker/browser/worker';

// <!-- NOTE: Webpack is not clever enough, and two bundles are created when using above -->
// const asyncInterface: DisruptorServiceWorkerAsync =
//   process.env.GATSBY_URQL_DEBUG === 'true'
//     ? graphqlAsyncInterface
//     : (disruptorServiceWorkerRemote as DisruptorServiceWorkerAsync);

const asyncInterface: DisruptorServiceWorkerAsync =
  disruptorServiceWorkerRemote as DisruptorServiceWorkerAsync;

export const remoteCartStateMachine = new RemoteCartStateMachine(
  asyncInterface,
  appEventDispatcher,
  checkoutState,
  __checkoutStoreInternal
);

export const GraphQLWorkerContext = React.createContext(asyncInterface);

export function useGraphqlWorker(): DisruptorServiceWorkerAsync {
  const context = useContext(GraphQLWorkerContext);
  if (canUseDom() && !context) {
    throw Error('GraphQLWorkerContext not initiated');
  }
  return context as DisruptorServiceWorkerAsync;
}

export const GraphQLWorkerProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const appDispatcher = useAppEventDispatcher();

  useEffect(() => {
    remoteCartStateMachine.start();

    return () => remoteCartStateMachine.stop();
  }, []);

  useEffect(() => {
    asyncInterface.listenToWorkerEvent(
      proxy((name, payload) => {
        appDispatcher.dispatch(name, payload);
      })
    );

    asyncInterface.listenToWorkerErrors(
      proxy((name, payload) => {
        appDispatcher.dispatchError(name, payload);
      })
    );

    return appDispatcher.subscribeToAll(
      <Name extends keyof AppEvents>(
        name: Name,
        ...[payload]: AppEvents[Name] extends undefined ? [] : [AppEvents[Name]]
      ) => {
        disruptorServiceWorkerRemote.handleDisruptorEvent({ name, payload });
      }
    );
  }, []);

  return (
    <GraphQLWorkerContext.Provider value={asyncInterface}>
      {children}
    </GraphQLWorkerContext.Provider>
  );
};
