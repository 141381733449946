import { Breakpoint } from 'lib/device/state';
import { useCurrentBreakpoint } from 'lib/hooks/useWindowDimensions';
import React, { Suspense, useEffect, useState } from 'react';

type Props<T> = T & {
  predicate: (breakpoint: Breakpoint) => boolean;
  LazyComponent: React.LazyExoticComponent<React.FC<T>>;
};

/**
 * Responsive wrapper that:
 *
 * 1. Allows the component to be used with Suspense without conditionals
 * 2. Always returns null in SSR, and then,if condition is met AFTER mount, it loads and renders
 *
 * As a result, a Responsive component MUST be compatible with being lazy
 */
function Responsive<T>({
  predicate,
  LazyComponent,
  ...props
}: Props<T>): React.ReactElement<any, any> | null {
  const [show, setShow] = useState(false);
  const breakpoint = useCurrentBreakpoint();

  useEffect(() => {
    breakpoint && setShow(predicate(breakpoint));
  }, [breakpoint]);

  if (!show) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyComponent {...(props as any)} />
    </Suspense>
  );
}

export default Responsive;
