import { canUseDom } from 'lib/util';
import { DisruptorServiceWorkerRemote } from './types';
import { wrap } from 'comlink';

// TODO: upgrade to shareworker if supported
// TODO: don't export after fully moved to COmlink
export const disruptorServiceWorker = (canUseDom() &&
  new Worker(
    /* webpackChunkName: "graphql-worker" */ new URL(
      './browser/worker.ts',
      import.meta.url
    )
  )) as Worker;

export const disruptorServiceWorkerRemote: DisruptorServiceWorkerRemote =
  disruptorServiceWorker && wrap(disruptorServiceWorker);

if (disruptorServiceWorker) {
  window.addEventListener('visibilitychange', () => {
    disruptorServiceWorkerRemote.handleWindowVisibilityChange(
      document.visibilityState === 'visible'
    );
  });
}
