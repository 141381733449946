import type { AppSiteNavItem } from '../../gatsby/types';
import { MAIN_NAVIGATION_ROUTER_KEY } from '../globalConfig';
import { findRouteInTree } from './global';

export const makeDynamicPath =
  (key: MAIN_NAVIGATION_ROUTER_KEY) => (tree: AppSiteNavItem, slug: string) =>
    [findRouteInTree(tree, key)?.path, slug].join('/');

export const makeProductPath = makeDynamicPath(
  MAIN_NAVIGATION_ROUTER_KEY.PRODUCT
);

export const makeIngredientPath = makeDynamicPath(
  MAIN_NAVIGATION_ROUTER_KEY.INGREDIENTS
);

export const makeBlogPostPath = makeDynamicPath(
  MAIN_NAVIGATION_ROUTER_KEY.BLOG
);

export const makeProductCatPath = makeDynamicPath(
  MAIN_NAVIGATION_ROUTER_KEY.ALL_PRODUCTS
);
