<script lang="ts">
  import { queryStore } from '@svelte/service/backend/queryStore';
  import { DisruptorServiceWorkerAsync } from '@svelte/service/worker/types';
  import { AppEventName } from 'lib/events/contracts';
  import { AppEventTarget } from 'lib/events/globalEvents';
  import { onDestroy } from 'svelte';
  import { MarketingNotifierStorage } from './storage/types';
  import BrowserMarketingNotifierStorage from './storage/MarketingNotifierStorage';
  import { newsletterBannerSeen } from '@svelte/state/newsletterBanner';
  import { useRoutePath } from '@svelte/data/routes/context';
  import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
  import { get } from 'svelte/store';

  export let eventHandler: AppEventTarget;
  export let queryWorker: DisruptorServiceWorkerAsync;
  export let storage: MarketingNotifierStorage =
    new BrowserMarketingNotifierStorage(eventHandler, queryWorker);

  const discount = 20;
  const waitTime = 10_000;
  // Avoid dispatching event more than once on app re-render (Gatsby/React safety)
  let hasRun = false;
  let landingPageRoute = useRoutePath(
    MAIN_NAVIGATION_ROUTER_KEY.MULTIFUNCTIONAL_LANDING
  );

  let timeout: NodeJS.Timeout;

  const userQuery = queryStore({
    key: 'session',
    variables: {},
    worker: queryWorker
  });
  $: currentUser = $userQuery.data?.currentUser;
  $: alreadySubscribed = currentUser?.subscribedToNewsletter;

  $: {
    if (currentUser) {
      clearTimeout(timeout);
      if (!$newsletterBannerSeen && !alreadySubscribed && !hasRun) {
        timeout = setTimeout(async () => {
          if (
            currentUser &&
            // don't trigger discount in landing page
            // TODO: rerun if user navigates out of the landing page to browse
            get(landingPageRoute).path !== window.location.pathname
          ) {
            hasRun = true;
            storage
              .shouldShowNewsletterPrompt(currentUser.id)
              .then(shouldShow => {
                if (shouldShow && currentUser) {
                  eventHandler.dispatch(AppEventName.NewsletterOfferPrompted, {
                    discount
                  });
                }
              })
              .catch(() => {});
          }
        }, waitTime);
      }
    }
  }

  onDestroy(() => {
    clearTimeout(timeout);
  });
</script>
