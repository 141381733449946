import type { AppSiteNavItem } from '../../gatsby/types';
import { MAIN_NAVIGATION_ROUTER_KEY } from '../globalConfig';
import { loggers } from '../log';

const log = loggers.navigation;

export function findInTree(
  tree: AppSiteNavItem,
  predicate: (item: AppSiteNavItem) => boolean
): AppSiteNavItem | undefined {
  const isMatch = predicate(tree);

  if (isMatch) {
    return tree;
  }

  for (const item of tree.items || []) {
    const found = item && findInTree(item, predicate);

    if (found) {
      return found;
    }
  }
}

export const findRouteInTree = (
  tree: AppSiteNavItem,
  key: MAIN_NAVIGATION_ROUTER_KEY
): AppSiteNavItem => {
  const output = findInTree(tree, item => item?.uiRouterKey === key);
  if (!output) {
    const err = new Error('Unable to find route in tree');
    log.error(err, { key, tree: JSON.stringify(tree, null, 4) });
    throw err;
  }

  return output;
};

export const findRouteInTreeByPath = (
  tree: AppSiteNavItem,
  path: string
): AppSiteNavItem | undefined => findInTree(tree, item => item?.path === path);

// export function findAllInTree(
//   tree: AppSiteNavItem,
//   predicate: (item: any) => boolean,
//   acc: AppSiteNavItem[] = []
// ): AppSiteNavItem[] {
//   if (tree.items) {
//     for (const item of tree.items) {
//       const isMatch = predicate(item);
//       if (isMatch && item) {
//         acc.push(item);
//       }

//       if (item?.items) {
//         findAllInTree(item, predicate, acc);
//       }
//     }
//   }

//   return acc;
// }
