import { getContext, setContext } from 'svelte';

import { AppEventTarget } from 'lib/events/globalEvents';

export const APP_DISPATCHER_CONTEXT_KEY = Symbol();

export const setAppDispatcherContext = (appDispatcher: AppEventTarget) =>
  setContext(APP_DISPATCHER_CONTEXT_KEY, appDispatcher);

export const getAppEventDispatcher = (): AppEventTarget => {
  const dispatcher = getContext<AppEventTarget | undefined>(
    APP_DISPATCHER_CONTEXT_KEY
  );

  if (!dispatcher) {
    throw Error('Missing AppEventDispatcher');
  }

  return dispatcher;
};
