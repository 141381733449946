import { AppEventName } from 'lib/events/contracts';
import { AppEventTarget } from 'lib/events/globalEvents';
import type { components } from '../../../service/dl-api';
import { AppSiteNavItem } from 'gatsby/types';
import { findRouteInTreeByPath } from 'lib/navigation/global';
import { loggers } from 'lib/log';
import { makeOpenApiClient } from '../../../service/dl-api/client';
// import { PageViewManager } from './pageViewManager';

const log = loggers.data;
const POLLING_INTERVAL = 2000;
const DEFAULT_BATCH_SIZE = 30;

export type AnalyticsPipelineEvent = components['schemas']['FrontendEvent'];
export type AnalyticsPipelineInput = AnalyticsPipelineEvent['payload'];
export type AnalyticsHandler = (batch: AnalyticsPipelineEvent[]) => void;

export interface AnalyticsPipelineClient {
  sendBatchJson: AnalyticsHandler;
  sendBatchBeacon: AnalyticsHandler;
}

export class AnalyticsPipelineBrowser implements AnalyticsPipelineClient {
  client = makeOpenApiClient({ keepalive: true });

  sendBatchJson = async (batch: AnalyticsPipelineEvent[]) => {
    const { error } = await this.client.POST('/events/json', {
      body: batch
    });
    if (error) {
      log.error(new Error('Unable to send analytics events'), { error });
    }
  };
  sendBatchBeacon = (batch: AnalyticsPipelineEvent[]) => {
    navigator.sendBeacon(
      `${process.env.GATSBY_OPENAPI_ENDPOINT}/events/text`,
      JSON.stringify(batch)
    );
  };
}

export class AnalyticsPipeline {
  private eventQueue: AnalyticsPipelineEvent[] = [];
  private batchSize: number;
  private intervalId: NodeJS.Timeout | null = null;
  // private pageViewManager: PageViewManager;

  constructor(
    readonly appNav: AppSiteNavItem,
    readonly client: AnalyticsPipelineClient,
    batchSize: number = DEFAULT_BATCH_SIZE
  ) {
    this.batchSize = batchSize;
    // this.pageViewManager = new PageViewManager();
  }

  public init(appDispatcher: AppEventTarget) {
    /**
     * NOTE: Page view events can't use subscribeMemo
     * because when document's visibility changes the same PageViewed will be published.
     * 
     * TODO: write a subscribeToManyMemo that memoises all events and so PageViewed won't
     * be triggered more than once if PageLeft is emitted before.
     */
    appDispatcher.subscribe(AppEventName.PageLeft, ({ location, duration }) => {
      this.addEvent({ event_name: 'page_leave', duration }, location);
    });
    appDispatcher.subscribe(AppEventName.PageViewed, ({ prev_duration }) => {
      this.addEvent({ event_name: 'page_view', prev_pageview_duration: prev_duration });
    });
    this.intervalId = setInterval(() => this.processBatch(), POLLING_INTERVAL);
    document.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange.bind(this)
    );
  }

  get isRunning(): boolean {
    return !!this.intervalId;
  }

  // Method to add an event to the queue
  public addEvent(payload: AnalyticsPipelineInput, location?: Location): void {
    if (this.isRunning) {
      let eventLocation = location || window.location;
      let route;
      try {
        route = findRouteInTreeByPath(this.appNav, eventLocation.pathname);
      } catch (error) {
        log.warn('Unable to match route by path', {
          pathname: eventLocation.pathname
        });
      }

      const parsed: components['schemas']['FrontendEvent'] = {
        page_name: route?.title || 'Unknown',
        payload,
        referrer: document?.referrer,
        timestamp: Date.now(),
        url: eventLocation.href,
        screen_hw: [window?.screen.height || 0, window?.screen.width || 0],
        viewport_hw: [window?.innerHeight || 0, window?.innerWidth || 0]
      };

      this.eventQueue.push(parsed);
    }
  }

  // Method to process a batch of events
  private processBatch(): void {
    if (this.eventQueue.length === 0) {
      return;
    }

    const batch = this.eventQueue.splice(0, this.batchSize);
    this.client.sendBatchJson(batch);
  }

  private handleVisibilityChange(event: Event) {
    event.preventDefault();
    if (document.visibilityState === 'hidden') {
      while (this.eventQueue.length) {
        const batch = this.eventQueue.splice(0, this.batchSize);
        this.client.sendBatchBeacon(batch);
      }
    }
  }

  // Method to stop the batch processing
  public stop(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }

    this.processBatch();
    // NOTE: this might cause issues if stop is called before event is fired on closing browser
    // document.removeEventListener(
    //   'visibilitychange',
    //   this.handleVisibilityChange.bind(this)
    // );
  }
}
