import { getContext, setContext } from 'svelte';
import { DisruptorServiceWorkerAsync } from '../types';

export const GRAPHQL_WORKER_KEY = Symbol();

export const setContextGraphqlWorker = (worker: DisruptorServiceWorkerAsync) =>
  setContext(GRAPHQL_WORKER_KEY, worker);

export const getContextGraphqlWorker = () =>
  getContext<DisruptorServiceWorkerAsync>(GRAPHQL_WORKER_KEY);
