import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import { StrapiProductCardFragment } from 'gatsby/graphqlTypes';
import { writable } from 'svelte/store';

/**
 * If StrapiProductCardFragment is defined
 * user is on a product page.
 * This stores shares the product data with
 * any subscribers.
 */
export const currentProductPageData =
  writable<StrapiProductCardFragment | null>(null);

export const currentRouteKeyStore = writable<
  MAIN_NAVIGATION_ROUTER_KEY | undefined
>();
