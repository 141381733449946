//@ts-expect-error
import metaPixelCode from '!raw-loader!./metaPixelCode';
import { AppEventName } from 'lib/events/contracts';
import useAppEventDispatcher from 'lib/events/hooks';
import { loggers } from 'lib/log';
import React, { useEffect } from 'react';

type MetaPixelProps = Readonly<{
  enabled: boolean;
}>;

function trackEvent(name: string, data?: unknown) {
  if (!window.fbq) {
    loggers.data.error(new Error('FBQ not ready'));
  } else {
    if (data) {
      window.fbq('track', name, data);
    } else {
      window.fbq('track', name);
    }
  }
}

const MetaPixel: React.FC<MetaPixelProps> = ({ enabled }) => {
  const appEventDispatcher = useAppEventDispatcher();

  useEffect(() => {
    if (enabled) {
      appEventDispatcher.subscribeMemo(AppEventName.PageViewed, () => {
        trackEvent('PageView');
      });

      appEventDispatcher.subscribeMemo(AppEventName.AddedToCart, () => {
        trackEvent('AddToCart');
      });

      appEventDispatcher.subscribeMemo(
        AppEventName.PurchaseCompleted,
        purchase => {
          trackEvent('Purchase', {
            value: purchase?.amounts?.subtotal,
            currency: 'GBP'
          });
        }
      );
    }
  }, [enabled]);

  if (!enabled) {
    return null;
  }

  return (
    <>
      <script dangerouslySetInnerHTML={{ __html: metaPixelCode }}></script>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=1357528868560682&ev=PageView&noscript=1"
        />
      </noscript>
    </>
  );
};

export default MetaPixel;
