import React from 'react';
import { canUseDom } from 'lib/util';
import { useEffect } from 'react';
import { makeMutationPromise } from '@svelte/service/backend/queryStore';
import { useGraphqlWorker } from 'state/context/GraphqlWorkerProvider';

type MarketingAttributionProps = PropsWithChildren;

const AD_TRACTION_PARAM = 'at_gd';
const AD_TRACTION_SOURCE = 'adtraction';

// NOTE: it has to be a component in order to use hooks in wrapRootElement
const MarketingAttribution: React.FC<MarketingAttributionProps> = ({
  children
}) => {
  const worker = useGraphqlWorker();

  useEffect(() => {
    const search = canUseDom() ? window.location.search : '';
    const params = new URLSearchParams(search);

    const utm_source = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');
    const atGd = params.get(AD_TRACTION_PARAM);

    if (utm_source === AD_TRACTION_SOURCE && utmMedium && utmCampaign && atGd) {
      makeMutationPromise({
        key: 'setAdtractionAttribution',
        variables: {
          attribution: {
            utmMedium,
            utmCampaign,
            atGd
          }
        },
        worker
      });
    }
  }, []);

  return <>{children}</>;
};

export default MarketingAttribution;
