<script lang="ts">
  import RouteProvider from '@svelte/data/routes/RouteProvider.svelte';
  import GraphqlClientProvider from '@svelte/service/worker/context/GraphqlClientProvider.svelte';
  import { AppSiteNavWithLocale } from 'gatsby/types';
  import AppEventDispatcherProvider from '@svelte/lib/events/AppDispatcherProvider.svelte';

  export let appNavTree: AppSiteNavWithLocale;
</script>

{#if appNavTree}
  <RouteProvider {appNavTree}>
    <AppEventDispatcherProvider>
      <GraphqlClientProvider>
        <slot />
      </GraphqlClientProvider>
    </AppEventDispatcherProvider>
  </RouteProvider>
{/if}
