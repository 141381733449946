import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';

import React, { useEffect } from 'react';
import {
  hydrateLocalCart,
  resetCart,
  updateCart,
  updateCheckoutInProgress
} from './stores/checkout';

import { AppEventName } from 'lib/events/contracts';
import { AppEventTarget } from 'lib/events/globalEvents';
import { updateDeliveryRules } from '@svelte/state/deliveryRules';
import useAppEventDispatcher from 'lib/events/hooks';
import useDeliveryRules from 'gatsby/graphql/useDeliveryRules';

const handleSessionChange =
  (eventDispatcher: AppEventTarget) =>
  (sessionUser: GqlSchemaWorker.SessionUserFragment) => {
    return hydrateLocalCart(eventDispatcher, sessionUser.id, sessionUser.cart);
  };

/**
 * AppStateSync makes sure that Application state objects
 * are kept in sync with:
 * - App Events
 * - Browser state (navigation)
 * - Backend state (via React Query)
 */
export const AppStateSync: React.FC<PropsWithChildren> = ({ children }) => {
  const deliveryRules = useDeliveryRules();
  const eventDispatcher = useAppEventDispatcher();

  useEffect(() => {
    const subs: Array<() => void> = [];

    /**
     * Init delivery rules to checkout state
     */
    updateDeliveryRules(deliveryRules);

    /**
     * Sync events with cart state
     */
    subs.push(
      eventDispatcher.subscribe(AppEventName.PurchaseCompleted, () => {
        updateCheckoutInProgress(false);
        resetCart();
      })
    );

    subs.push(
      eventDispatcher.subscribe(
        AppEventName.Authenticated,
        handleSessionChange(eventDispatcher)
      )
    );

    subs.push(
      eventDispatcher.subscribe(AppEventName.LoggedIn, ({ sessionUser }) =>
        handleSessionChange(eventDispatcher)(sessionUser)
      )
    );

    subs.push(
      eventDispatcher.subscribe(
        AppEventName.LoggedOut,
        handleSessionChange(eventDispatcher)
      )
    );

    subs.push(
      eventDispatcher.subscribe(AppEventName.ClaimedDiscount, data => {
        updateCart(eventDispatcher, data.cart);
      })
    );

    return () => {
      for (const unsubscribe of subs) {
        unsubscribe();
      }
    };
  }, []);

  return <>{children}</>;
};
