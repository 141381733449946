import { AppEventTarget } from 'lib/events/globalEvents';
import { MarketingNotifierStorage } from './types';
import { AppEventName } from 'lib/events/contracts';
import { NotificationKind } from '@svelte/lib/notifications/types';
import { makeQueryPromise } from '@svelte/service/backend/queryStore';
import { DisruptorServiceWorkerAsync } from '@svelte/service/worker/types';

export type Entry = {
  uid: string;
  promptDiscardedTimestamp: number;
};

export const makeStorageKey = (uid: string) => `nws-${uid}`;

export default class BrowserMarketingNotifierStorage
  implements MarketingNotifierStorage {
  #cache: Entry | undefined;

  constructor(
    readonly appEventDispatcher: AppEventTarget,
    readonly queryWorker: DisruptorServiceWorkerAsync
  ) {
    appEventDispatcher.subscribe(
      AppEventName.NotificationDismissed,
      notification => {
        if (notification.kind === NotificationKind.NewsletterPrompt) {
          this.setNewsletterPromptDiscarded();
        }
      }
    );
  }

  private async setNewsletterPromptDiscarded() {
    const { data } = await makeQueryPromise({
      key: 'session',
      worker: this.queryWorker,
      variables: {}
    });
    if (data) {
      const uid = data.currentUser.id;
      const entry: Entry = {
        uid,
        promptDiscardedTimestamp: new Date().getTime()
      };
      window.localStorage.setItem(makeStorageKey(uid), JSON.stringify(entry));
    }
  }

  async getEntry(uid: string): Promise<Entry | undefined> {
    if (this.#cache && this.#cache.uid === uid) {
      return this.#cache;
    }

    const data = await window.localStorage.getItem(makeStorageKey(uid));
    if (data) {
      const entry = JSON.parse(data);
      this.#cache = entry;
      return entry;
    }
  }

  async shouldShowNewsletterPrompt(uid: string): Promise<boolean> {
    const entry = await this.getEntry(uid);
    if (entry) {
      var limitDate = new Date();
      limitDate.setDate(limitDate.getDate() + 7);
      return (
        uid === entry.uid &&
        entry.promptDiscardedTimestamp > limitDate.getTime()
      );
    }

    return true;
  }
}
