<script lang="ts">
  import { AppSiteNavWithLocale } from 'gatsby/types';
  import { setRoutesContext } from './context';

  export let appNavTree: AppSiteNavWithLocale;

  setRoutesContext(appNavTree);
</script>

<slot />
