import { CheckoutState, CheckoutStateStore } from 'state/stores/checkout';
import { Readable, Writable } from 'svelte/store';

import { AppErrorName } from 'lib/events/errors';
import { AppEventName } from 'lib/events/contracts';
import { AppEventTarget } from 'lib/events/globalEvents';
import BackOff from 'backo2';
import { loggers } from 'lib/log';
import { runOrRetryAsync } from 'lib/retryBackoff';
import { DisruptorServiceWorkerAsync } from '@svelte/service/worker/types';
import { makeMutationPromise } from './backend/queryStore';

const log = loggers.service;

const INIT_BACKOFF_MAX = 60000;

export class RemoteCartStateMachine {
  private subscriptions: Array<() => void> = [];

  constructor(
    readonly graphqlWorker: DisruptorServiceWorkerAsync,
    readonly eventDispatcher: AppEventTarget,
    readonly checkoutState: Readable<CheckoutState>,
    readonly checkoutStoreInternal: Writable<CheckoutStateStore>
  ) {}

  start() {
    const subs = this.subscriptions;

    /**
     * Add, Remove and Edit items in cart are considered optimistic updates.
     * This machine is responsible for handling events with mutations.
     * However, errors should be handled separately in an async fashion
     * (e.g. as a toast message)
     * The case of customer details is different because frontend needs
     * to have access to validation errors
     */
    subs.push(
      this.eventDispatcher.subscribe(AppEventName.AddedToCart, data => {
        const retryBackoff = runOrRetryAsync(
          new BackOff({ min: 100, max: INIT_BACKOFF_MAX })
        );
        return retryBackoff(() =>
          makeMutationPromise({
            key: 'addItemToCart',
            variables: {
              productId: data.id,
              skuId: data.sku.data.id,
              qty: data.sku.qty
            },
            worker: this.graphqlWorker
          })
            .then(({ error, data }) => {
              if (error || !data) {
                throw Error;
              }

              this.checkoutStoreInternal.update(state => {
                const { cart } = state;
                const remoteId = data.addCartItem.id;
                /**
                 * Set orderId if order is just created
                 */
                if (cart && cart.id !== remoteId) {
                  return {
                    ...state,
                    cart: {
                      ...cart,
                      id: remoteId
                    }
                  };
                }

                return state;
              });
            })
            .catch(error => {
              // TODO: remove from cart
              // or cart listen to AppErrorName.RemoteCartMutation
              this.eventDispatcher.dispatchError(
                AppErrorName.RemoteCartMutation,
                {
                  error,
                  message: 'Unable to add item'
                }
              );
            })
        );
      })
    );

    subs.push(
      this.eventDispatcher.subscribe(AppEventName.RemovedFromCart, data => {
        const retryBackoff = runOrRetryAsync(
          new BackOff({ min: 100, max: INIT_BACKOFF_MAX })
        );
        return retryBackoff(() =>
          makeMutationPromise({
            key: 'removeCartItem',
            variables: data,
            worker: this.graphqlWorker
          })
            .then(({ error }) => {
              if (error) {
                throw error;
              }
            })
            .catch(error => {
              this.eventDispatcher.dispatchError(
                AppErrorName.RemoteCartMutation,
                { error, message: 'Unable to remove item' }
              );
            })
        );
      })
    );

    subs.push(
      this.eventDispatcher.subscribe(AppEventName.EditedCartItem, variables =>
        makeMutationPromise({
          key: 'editCartItemQty',
          variables,
          worker: this.graphqlWorker
        })
          .then(({ error }) => {
            if (error) {
              throw error;
            }
          })
          .catch(error => {
            const retryBackoff = runOrRetryAsync(
              new BackOff({ min: 100, max: INIT_BACKOFF_MAX })
            );
            return retryBackoff(() =>
              this.eventDispatcher.dispatchError(
                AppErrorName.RemoteCartMutation,
                {
                  error,
                  message: 'Unable to edit item'
                }
              )
            );
          })
      )
    );
  }

  stop() {
    log.info('Stopping cart listeners');
    for (const unsubscribe of this.subscriptions) {
      unsubscribe();
    }
  }
}
